<template>
    <div class="awardRule">
        <div class="container flex">
            <div class="tree">
                <div class="tree-name">职位管理</div>
                <a-tree :selected-keys="selectedKeys" :tree-data="treeData" :replace-fields="treeReplaceFields" @select="_treeSelect"/>
            </div>
            <div class="content">
                <div class="table">
                    <div class="flex" style="padding-bottom: 10px;">
                        <div class="search-div">

                        </div>
                        <a-button type="primary" @click="_addModel()">添加</a-button>
                    </div>
                    <a-table :columns="tableColumns" :data-source="tableData" :customRow="_clickRow" :rowKey="'Id'" :pagination="false">
                        <div slot="operation" slot-scope="text, record,index" style="color: #2994FF">
                            <span class="iconfont tab-icon icon-zhongmingming-" style="margin-right: 8px;" @click="_setData(index)"></span>
                            <a-popconfirm title="你确定要删除吗?" ok-text="确定" cancel-text="取消" @confirm="_del(index)">
                                <span class="iconfont tab-icon icon-shanchu-"></span>
                            </a-popconfirm>
                        </div>
                    </a-table>
                    <div class="table-footer">
                        <span>
                            <a-checkbox>（累计推荐＞5）对应级别奖励积分*1.2</a-checkbox>
                        </span>
                        <span>
                            <a-checkbox>（累计推荐＞10）对应级别奖励积分*1.5</a-checkbox>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <tableHeader :showMOdelTest="showMOdelTest" @getData="_tableGetData"></tableHeader>
        <a-drawer title="新增职级奖励规则" placement="right" :closable="false" width="500" :visible="addRuleVisible" @close="_closeVisible">
            <div class="add-rule-content">
                <div class="row">
                    <div class="row-label">关联职级</div>
                    <div class="row-input">
                        <a-select style="width: 100%" v-model="Position">
                            <a-select-option :value="index" v-for="(item,index) in selectData">{{item.PositionName}}</a-select-option>
                        </a-select>
                    </div>
                </div>

                <div class="row">
                    <div class="row-label">入职奖励</div>
                    <div class="row-input">
                        <a-input placeholder="请输入数字" v-model="EntryPrize"></a-input>
                    </div>
                </div>

                <div class="row">
                    <div class="row-label">转正奖励</div>
                    <div class="row-input">
                        <a-input placeholder="请输入数字" v-model="FullPrize"></a-input>
                    </div>
                </div>

                <div class="row">
                    <div class="row-label">规则说明</div>
                    <div class="row-input">
                        <a-textarea v-model="Rule"></a-textarea>
                    </div>
                </div>
            </div>
            <div class="alert-footer">
                <a-button style="margin-right: 25px" @click="_closeVisible">取消</a-button>
                <a-button type="primary"  @click="_saveAdd()">确定</a-button>
            </div>
        </a-drawer>
    </div>
</template>

<script>
    import tableHeader from '../../components/tableHeader/filterBox';
    export default {
        name: "awardRule",
        components:{
            tableHeader
        },
        data(){
            return {
                selectedKeys: [],
                treeData: [],
                treeReplaceFields:{
                    key: 'value'
                },
                addRuleVisible: false,
                tableColumns: [
                    {
                        title: '岗位级别',
                        dataIndex: 'PositionName',
                        align: 'center',
                    },
                    {
                        title: '奖励积分',
                        dataIndex: 'Total',
                        align: 'center',
                    },
                    {
                        title: '入职奖励',
                        dataIndex: 'EntryPrize',
                        align: 'center',
                    },
                    {
                        title: '转正奖励',
                        dataIndex: 'FullPrize',
                        align: 'center',
                    },
                    {
                        title: '规则说明',
                        dataIndex: 'Rule',
                        align: 'center',
                    },
                    {
                        title: '创建时间',
                        dataIndex: 'CreateTime',
                        align: 'center',
                    },
                    {
                        title: '操作',
                        dataIndex: 'operation',
                        align: 'center',
                        scopedSlots: { customRender: 'operation' },
                    }
                ],
                tableData: [],
                selectData: [],
                tabs: 0,
                showMOdelTest: {
                    show: false,
                    type: 'content',
                    init: ''
                },
                searchList: [],

                setId: '',
                Position: '',
                EntryPrize: '',
                FullPrize: '',
                Rule: '',
            }
        },
        created(){
            this._treeData();
            this._infoData();
        },
        methods: {
            _setData(i){
                let  Position = '';
                let self = this;
                for(let index in this.selectData){
                    if(self.selectData[index].PositionName == self.tableData[i].PositionName){
                        Position = index;
                    }
                }
                this.Position = Position;
                this.EntryPrize = this.tableData[i].EntryPrize;
                this.FullPrize = this.tableData[i].FullPrize;
                this.Rule = this.tableData[i].Rule;
                this.setId = this.tableData[i].Id;
                this.addRuleVisible = true;
            },
            _saveAdd(){
                this.$axios.post(8000075,{
                    Id: this.setId,
                    PositionId: this.selectData[this.Position].Id,
                    PositionName: this.selectData[this.Position].PositionName,
                    EntryPrize: this.EntryPrize,
                    FullPrize: this.FullPrize,
                    Rule: this.Rule
                },res=>{
                    if(res.data.code==1){
                        this.$message.success(res.data.msg);
                        this._closeVisible();
                        this._infoData();
                    }else{
                        this.$message.error(res.data.msg)
                    }
                })
            },
            _del(i){
                this.$axios.post(8000072,{
                    Id: this.tableData[i].Id
                },res=>{
                    if(res.data.code==1){
                        this.tableData.splice(i,1);
                        this.$message.success(res.data.msg);
                    }else{
                        this.$message.error(res.data.msg)
                    }
                })
            },
            _infoData(){
                this.$axios.get(8000071,{
                    PositionId: this.selectedKeys[0]
                },res=>{
                    this.tableData = res.data.data;
                })
            },
            _treeSelect(selectedKeys){
                this.selectedKeys = selectedKeys;
                this._infoData();
            },
            _treeData(){
                this.$axios.get(8000073,{},res=>{
                    this.treeData = res.data.data;
                    this.treeData.unshift({title: '全部',value: ''})
                });
                this.$axios.get(8000074,{},res=>{
                    this.selectData = res.data.data;
                });
            },
            _addModel(){
                this.addRuleVisible = true;
            },
            _closeVisible(){
                this.Position = '';
                this.EntryPrize = '';
                this.FullPrize = '';
                this.Rule = '';
                this.setId = '';
                this.addRuleVisible = false;
            },
            _clickRow(record, index){
                let self = this;
                return {
                    on: {
                        click: () => {
                            console.log('点击了table列表')
                        }
                    }
                }
            },
            _showMOdelTest(type,e,list,txt){
                this.selectType = txt;
                let init = '';
                this.searchList.map(res=>{
                    if(res.name==txt){
                        init = res.value;
                    }
                });
                this.showMOdelTest.list = list;
                this.showMOdelTest.type = type;
                this.showMOdelTest.show = true;
                this.showMOdelTest.init = init;
                this.showMOdelTest.event = e;
            },
            _tableGetData(val){
                let isNew = true;
                this.searchList.map(res=>{
                    if(res.name==this.selectType){
                        res.value = val;
                        isNew = false;
                    }
                });
                if(isNew){
                    this.searchList.push({name: this.selectType,value: val})
                }
            },
        },
        computed: {
            rowSelection() {
                return {
                    onChange: (selectedRowKeys, selectedRows) => {
                        console.log(selectedRows);
                    },
                };
            },
        },
    }
</script>

<style scoped lang="less">
    .awardRule{
        height: 100%;
        border-radius: 5px;
        .container{
            align-items: end;
            height: 100%;
        }
        .tree{
            width: 220px;
            margin-right: 10px;
            padding: 10px 5px 0;
            border-radius: 5px;
            min-height: 100%;
            background: #fff;
            .tree-name{
                padding: 0 5px 0;
            }
        }
        .content{
            flex: 1;
            padding-top: 10px;
            min-height: 100%;
            background: #fff;
            border-radius: 5px;
            .table{
                width: 98%;
                margin: 0 auto;
                .filter-icon{
                    margin-left: 5px;
                    color: #BDBDBD;
                    font-size: 14px;
                }
                .table-footer{
                    margin-top: 15px;
                    span{
                        margin-right: 20px;
                    }
                }
            }
            .tab-icon{
                font-size: 18px;
                color: #808080;
            }
        }
    }
    .alert-footer {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        border-top: 1px solid #e9e9e9;
        padding: 10px 16px;
        background: #fff;
        button {
            width: 120px;
        }
    }
    .add-rule-content{
        .row{
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            .row-label{
                width: 100px;
                color: #575D6A;
                font-size: 15px;
            }
            .row-input{
                flex: 1;
                input{
                    width: 100%;
                }
                textarea{
                    width: 100%;
                    height: 70px;
                    resize: none;
                }
            }
        }
    }
</style>

<template>
    <div class="postAdmin">
        <a-tabs type="card" default-active-key="1" @change="_getCode">
            <a-tab-pane key="1" tab="内推管理">
                <!--<neituiAdmin></neituiAdmin>-->
            </a-tab-pane>
            <a-tab-pane key="2" tab="职位标准">
                <!--<postAdmin></postAdmin>-->
            </a-tab-pane>
            <a-tab-pane key="3" tab="奖励规则">
                <!--<postStandard></postStandard>-->
            </a-tab-pane>
        </a-tabs>
        <div class="container">
            <neituiAdmin v-if="code==1"></neituiAdmin>
            <postStandard v-if="code==2"></postStandard>
            <awardRule v-if="code==3"></awardRule>
        </div>
    </div>
</template>

<script>
    import neituiAdmin from './neituiAdmin';
    import awardRule from './awardRule';
    import postStandard from './postStandard';
    export default {
        name: "postAdmin",
        components: {
            neituiAdmin,awardRule,postStandard
        },
        data(){
            return {
                code: 1
            }
        },
        methods: {
            _getCode(code){
                this.code = code;
            }
        }
    }
</script>

<style scoped lang="less">
    .postAdmin{
        height: 100%;
    }
    .container{
        height: calc(~'100% - 60px');
    }
    .ant-tabs .ant-tabs-top-content.ant-tabs-content-animated{
        height: 100% !important;
    }
</style>
